#container:hover #noPropText { visibility: hidden; }
#container:hover #noPropExpText { visibility: hidden; }

#noPropText {
    font-size: 40px;
    margin: 0;
    float: right;
    color: white;
    text-shadow: 0 0 3px rgb(0, 0, 0), 0 0 5px rgb(0, 0, 0);
    visibility: visible;
}

#noPropExpText {
    position: absolute;
    top: 40px;
    right: 0px;  
    font-size: 15px;
    margin: 0;
    float: right;
    color: white;
    text-shadow: 0 0 3px rgb(0, 0, 0), 0 0 5px rgb(0, 0, 0);
    visibility: visible;
}