#portfolio_container {
  border: 1px solid white;
  margin-top: 10px;
  /* padding: 10px; */
  border-radius: 5px;
}
#portfolio_button_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
#portfolio_button {
  height: 50%;
  justify-content: center;
  align-items: center;
  margin: auto;
}
#portfolio_container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height:100vh" */
}
#portfolio_list_container {
  background: white;
  margin-top: 10px;
  border-radius: 5px;
  min-height: 50%;
  padding: 10px;
}
