.solid {
  border: 0.1px solid #091e49;
  background-color: #091e49;
  margin: 0%;
}

.header {
  max-height: 30px;
  display: flex;
  align-items: center;
  flex: "1 1 1";
}

.metrics-panel {
  overflow: "auto";
  align-items: "center";
}

.action-plan {
  /* align-items: stretch; */
  /* width: 100%; */
  background: #205a81;
  border: 1px solid black;
}
#plan_button_active {
  /* flex: 1 1 1; */
  /* width: 20%; */
  background-color: lightblue;
  /* color: white !important; */
  /* border: 2px solid #113d64; */
  border: 1px solid black;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
#plan_button_disabled {
  /* flex: 1 1 1; */
  /* width: 20%; */
  background-color: #205a81;
  color: grey !important;
  /* border: 2px solid #113d64; */
  border: 1px solid black;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.card_actionplan {
  border: 1px solid black;
}
.edit_button_container {
  width: 95%;
  margin: auto;
  /* border: 1px solid black; */
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
}
