.home_background {
  /* min-width: 800px; */
  min-height: 200px;
  flex: 1;
  /* margin: 10px; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 2000px;
  width: 90%;
  background-color: #113d64 !important;
  overflow: auto;
  padding: 20px;
  border-radius: 5px;
  /* background-image: url("../../img/home_backgroun.png"); */
  /* background-attachment: fixed; */
  background-size: 100% 100%;
}

.solid {
  border: 0.05px solid #091e49;
  background-color: #091e49;
  margin: 0%;
}
