.MuiAppBar-colorPrimary {
    color: #fdd97d;
    background-color: #8d2242;
}

@media (min-width: 600px) {
    .MuiToolbar-regular {
        min-height: 60px;
    }
}
.NavbarItems {
    background: linear-gradient(90deg, #8d2242 0%, #d95552 100%);
    box-shadow: none;
    height: 35px;
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 1.2rem;
    min-height: 35px;
}
.MuiToolbar-root.MuiToolbar-regular.NavbarItems.MuiToolbar-gutters {
    min-height: 35px;
}
#beta_container {
    display: flex;
    align-items: center;
    padding-bottom: 0px;
    margin-bottom: 0px;
    width: 24px;
}
#beta {
    color: red;
    display: flex;
    align-items: center;
    transform: rotate(90deg);
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.nav-menu {
    /* display: grid; */
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: left;
    /* width: 80vw; */
    padding-left: 0px;
    margin: 0;
}

.nav-links {
    color: #fdd97d;
    text-decoration: none;
    text-align: center;
    height: 22px;
}

.nav-links:hover {
    /* background-color: #510e24; */
    border-radius: 4px;
    transition: all 0.2s ease-out;
    cursor: pointer;
}

.fa-bars {
    color: #510e24;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }
    .MuiToolbar-root.MuiToolbar-regular.NavbarItems.MuiToolbar-gutters {
        min-height: 35px;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 220px;
        position: absolute;
        top: 35px;
        padding-top: 8px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #d95552;
        padding-inline-start: 0px;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        height: 22px;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #510e24;
        border-radius: 0;
        cursor: pointer;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: -11px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #510e24;
        font-size: 2rem;
    }
}