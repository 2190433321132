.solid {
  border: 0.1px solid #091e49;
  background-color: #091e49;
  margin: 0%;
}

.header {
  max-height: 30px;
  display: flex;
  align-items: center;
}

.upperContentDiv {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 80vw;
  max-width: 80vw;
}

.lowerContentDiv {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  max-width: 80vw;
}
.epc_dgm_container {
  background: white;
  /* padding: 10px; */
}
#epc_box {
  margin: auto;
  /* width: 90%; */
  /* max-width: 500px; */

  /* padding-top: 5px; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* background: #ecfeff; */
}
.top_headline {
  background: white;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  /* height: 80% */
}
.headline_result {
  font-size: 20px;
  font-weight: 500;
}
.headline_result_sub {
  font-size: 16px;
  font-weight: 500;
}
#twice {
  line-height: 200% !important;
  padding-top: 20px;
  padding-bottom: 20px;
}
.panel_title {
  padding: 10px 0px 5px 10px;
  font-weight: 700;
  font-size: 16px;
}
.panel_content {
  padding: 10px 0px 5px 10px;
  /* font-weight: 500; */
}
.figure_title {
  font-size: 10px;
}
#epc_freq {
  max-height: 300px;
  margin: auto;
}
#epc_diagram {
  max-height: 300px;
}
#radar_chart {
  height: 100%;
  width: 100%;
}
