#rating_button {
    /* flex: 1 1 1; */
    /* width: 20%; */
    /* color: white !important; */
    /* border: 2px solid #113d64; */
    width: 100%;
    height: 35px;
    border: 1px solid black;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0px;
  }
