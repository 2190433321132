.filterPanel {
    text-align: left;
    width: auto;
    background-color: #f3f3f3;
    padding: 15px;
    border-radius: 5px;
    /* border: 2px solid #000000; */
}
.filterPanel::after {
    content: "";
    clear: both;
    display: table;
}

#selectPanel {
    text-align: left;
}
#react-select-2-listbox{
    z-index: 9;
}
.locationBox { 
    width: 100%
}
.cards {
    background-color: white;
    border: 1px solid #d5d5d5;
    /* border-radius: 10px; */
    margin: 5px;
    text-align: left;
    padding: 4px;
}
.panelButtons {
    color: black;
    background-color: white;
    margin: 5px;
    float: left;
    border: 1px solid #000000;
    border-radius: 5px;
    height: max-content;
    font-size: 17px;
}
.panelButtons:disabled {
    background-color: grey;

}