#container:hover #CO2text { visibility: hidden; }
#container:hover #CO2exptext { visibility: hidden; }

#CO2text {
    font-size: 40px;
    margin: 0;
    float: right;
    text-shadow: 0 0 3px #fff, 0 0 5px #fff;
    visibility: visible;
}

#CO2exptext {
    position: absolute;
    top: 40px;
    right: 0px;  
    font-size: 15px;
    margin: 0;
    float: right;
    text-shadow: 0 0 3px #fff, 0 0 5px #fff;
    visibility: visible;
}