.baseDiv {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.solid {
  border: 0.1px solid #091e49;
  background-color: #091e49;
  margin: 0%;
}

.header {
  max-height: 30px;
  display: flex;
  align-items: center;
}


.pound_icon {
  height: 80px;
  width: 80px;
}

.i_symbol {
  height: 80px;
  width: 80px;
}

.checked_icon {
  height: 80px;
  width: 80px;
}

.table {
  padding: 2% 0 2% 0;
  color: white;
}
