.baseDiv {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.solid {
  border: 0.1px solid #091e49;
  background-color: #091e49;
  margin: 0%;
}

.header {
  max-height: 30px;
  display: flex;
  align-items: center;
}


.assumptionsContent {
  padding-top: 10px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
