#btn_SSC.ui.large.fluid.button {
  /* padding: 10px 20px; */
  color: white;
  width: 102.3%;
  margin: 10px auto;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  border-radius: 5px;
  background-color: #091e49;
  border-color: #091e49;
}
.loginTitle {
  font-size: 28px;
  /* font-weight: bold; */
  text-align: center;
  color: #091e49;
  /* text-shadow: 1px 1px 2px #d95552; */
}
/* .ui.stacked.segment.loginPanel {
  background-color: #510e24;
} */
#signUpPanel.ui.message {
  background-color: #091e49;
  color: #fdd97d;
}
.login-page {
  background-color: white;
  width: 100%;
  height: 100vh;
}
#log-in-container {
  width: 350px;
  height: 300px;
  margin: auto;
  margin-top: 200px;
}
#login_title_container {
  text-align: center;
  padding-bottom: 20px;
}
#log_in_form {
  width: 100%;
  /* margin: 10px auto; */
  padding-top: 10px;
  padding-bottom: 10px;
}
.loginPanel {
  width: 100%;
}
