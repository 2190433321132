.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.body {
  background-color: #205a81;
}
.baseDiv {
  min-width: 100vw;
  min-height: 100vh;
  background-color: #205a81;
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.baseDiv_new {
  background-color: #205a81;
  display: flex;
  justify-content: center;
  align-items: stretch;
  min-height: 100vh;
}
.card {
  min-height: 200px;
  flex: 1;
  /* margin: 10px; */
  /* display: flex; */
  flex-direction: column;
  align-items: stretch;
  /* max-width: 2000px; */
  /* width: 90%; */
  background-color: #205a81 !important;
  /* overflow: auto; */
  padding: 10px;
  border-radius: 5px;
}
.action-plan_inner {
  width: 100%;
  margin: auto;
  border: 1px solid black;
  /* margin-top: 20px; */
  margin-bottom: 20px;
  overflow-x: scroll;
  overflow-y: hidden;

}

.headerText {
  color: white;
  padding-right: 4px;
  padding-bottom: 10px;
}
.redText {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode";
  color: #f25d50;
  padding-left: 5px;
  padding-bottom: 10px;
}
.panel_card {
  background: white;
  height: 100%;
  width: 100%;
}

.panel_card_graphs {
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.panel_card_sub {
  background: white;
  height: 100%;
  width: 100%;
}
.myLoading {
  height: 100%;
  width: 100%;
}
