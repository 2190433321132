/* .card {
    min-width: 800px;
    min-height: 200px;
    flex: 1;
    margin: 10px;
} */

.solid {
  border: 0.1px solid #091e49;
  background-color: #091e49;
  margin: 0%;
}

.header {
  max-height: 30px;
  display: flex;
  align-items: center;
}

.listItem {
  height: 27px;
}

.heading_box_number {
  font-size: min(30px, 6vw);
  font-weight: bold;
}

.heading_box_preunit {
  font-size: 18px;
}

.heading_box_unit {
  font-size: 14px;
  color: #555555;
}

.sun_diagram_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}

.sun_diagram {
  position: relative;
  z-index: 1;
  /* width: 90%; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* top: 0;
  left: 0; */
  /* filter: opacity(50%); */
  /* border: 1px red solid; */
}

.sun_radiation {
  position: absolute;
  z-index: 0;
  width: 75%;
  /* margin: auto; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sun_radiation {
  position: absolute;
  z-index: 0;
  width: 75%;
  /* margin: auto; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.results_container {
  display: flex;
  justify-content: center;
  align-content: stretch;
  flex-direction: column;
}

.diagram_title {
  text-align: center;
  padding-bottom: 20px;
  font-weight: bold;
}
