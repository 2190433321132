.summary_test_number_bold {
  font-size: 20px;
  /* font-weight: bolder; */
  color: #3e3e3e;
  /* font-family: "gilroybold"; */
}

.summary_test_number {
  font-size: 16px;
  /* font-family: "gilroysemibold"; */
}
.summary_sub {
  font-size: 16px;
  /* font-family: "gilroysemibold"; */
}

a {
  color: #fff
}
